import {enableProdMode, isDevMode} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {connectServiceWorker, regiserServiceWorker} from '@pwa';

import {environment} from '@shared-types';
import {AppComponent, appConfig} from './app';
import {beforeAppBootstrap} from './app/app-config/before-app-bootstrap/before-app-bootstrap';
import {initAppConfigurations} from './app/app-config/init-app-config.logic';
import './polyfills';

if (environment.production && !isDevMode()) {
	enableProdMode();
}

initAppConfigurations(environment.production).then(() => {
	regiserServiceWorker().then((registration: ServiceWorkerRegistration | null) => {
		if (registration) console.debug('[Service Worker (main file)] registered successfully', registration);
		beforeAppBootstrap()
			.then(() => bootstrapApplication(AppComponent, appConfig()))
			.then(appRef => {
				console.debug('[DocCycle] App is bootstrapped!');
				if (registration) {
					const injector = appRef.injector;
					connectServiceWorker(injector)
						.then(connectionResult => {
							if (connectionResult) console.debug('[DocCycle] Service Worker connected successfully');
						})
						.catch(error => console.error('[DocCycle] Error connecting Service Worker:', error));
				}
			});
	});
});
