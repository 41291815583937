import {JsonPipe, NgClass, NgTemplateOutlet} from '@angular/common';
import {AfterViewInit, Component, effect, HostBinding, inject, OnInit, Signal, viewChild, ViewChild} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {Router, RouterModule} from '@angular/router';
import {appInfoActions, VersionService} from '@cron/data-access';
import {DomRefererDirective, Loader, LoaderService, ToastService} from '@utils';
import {NgxUiLoaderComponent, NgxUiLoaderModule} from 'ngx-ui-loader';
import {Toast, ToastModule} from 'primeng/toast';
import {SidenavService} from '@app-layout/sidenav/data-access';
import {MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/tooltip';
import {SidebarModule} from 'primeng/sidebar';
import {SidenavComponent} from '@app-layout/sidenav/feature-sidenav';
import {Store} from '@ngrx/store';
import {MAT_SELECT_SCROLL_STRATEGY_PROVIDER} from '@angular/material/select';
import {MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/menu';
import {DirectionConditionDirective, DirectionDirective} from '@shared/direction';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'primeng/tooltip';
import {MessageService} from 'primeng/api';
import {DialogDirective, GlobalDialoglService} from '@shared/dialog';
import {ReportBugComponent, ReportBugComponentInputs} from '@error-management/feature-report-bug';
import {DialogModule} from 'primeng/dialog';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {LetDirective} from '@shared/ui';
import {AsCastDirective} from 'as-cast';
import {AuthService} from '@auth/data-access';
import {VersionManagementService} from '@pwa';
import {setUser} from '@sentry/angular';

@Component({
	standalone: true,
	imports: [
		RouterModule,
		MatButtonModule,
		NgxUiLoaderModule,
		NgClass,
		ToastModule,
		SidebarModule,
		SidenavComponent,
		DirectionConditionDirective,
		TranslateModule,
		TooltipModule,
		NgTemplateOutlet,
		JsonPipe,
		DialogDirective,
		ReportBugComponent,
		DialogModule,
		DomRefererDirective,
		OverlayPanelModule,
		LetDirective,
		DirectionDirective,
		AsCastDirective,
	],
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER, MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER, MAT_SELECT_SCROLL_STRATEGY_PROVIDER],
})
export class AppComponent implements OnInit, AfterViewInit {
	/* -------------------------------------------------------------------------------------------------------------- */

	protected ReportBugComponentInputs!: ReportBugComponentInputs;
	/* ---------------------------------------------- GLOBAL CONSTANTS ---------------------------------------------- */
	protected readonly GLOBAL_LOADER_ID: string = Loader.GLOBAL_LOADER_ID;
	protected readonly GlobalDialogControlService = GlobalDialoglService;

	/* ------------------------------------------------ SERVICES ------------------------------------------------ */
	protected readonly loaderService: LoaderService = inject(LoaderService);
	private readonly versionService: VersionService = inject(VersionService);
	protected readonly sidenavService: SidenavService = inject(SidenavService);
	private readonly store: Store = inject(Store);
	protected readonly toastService: ToastService = inject(ToastService);
	private readonly messageService: MessageService = inject(MessageService);
	protected readonly globalDialogControlService: GlobalDialoglService<any> = inject(GlobalDialoglService);
	private readonly authService: AuthService = inject(AuthService);
	/* -------------------------------------------------------------------------------------------------------------- */
	private readonly router: Router = inject(Router);
	private versionManager: VersionManagementService = inject(VersionManagementService);

	/* --------------------------------------------- CHILD ELEMENTS --------------------------------------------- */
	@ViewChild('GLOBAL_LOADER')
	private set __Global_App_loader__(globalLoaderRef: NgxUiLoaderComponent) {
		this.loaderService.setGlobalLoaderRef(globalLoaderRef);
	}

	readonly toastRef: Signal<Toast> = viewChild('AppToast', {read: Toast});

	/* ---------------------------------------------- HOST BINDINGS ---------------------------------------------- */
	@HostBinding('attr.dc-version') readonly version: string = this.versionService.getLocalFrontendVersion();

	/* ----------------------------------------------- CONSTRUCTOR ----------------------------------------------- */
	constructor() {
		this.store.dispatch(appInfoActions.reloadVersion());
		effect(
			() => {
				const toastRef = this.toastRef();
				if (toastRef && this.toastService) this.toastService.toastRef = toastRef;
			},
			{allowSignalWrites: true},
		);

		const authEffectRef$ = effect(() => {
			const isAuthenticated = this.authService.isAuthenticated();
			if (isAuthenticated) {
				const user = this.authService.ActiveUser.activeUser();
				if (user) {
					tryCatchFn(() => {
						setUser({
							id: user.id,
							enabled: user.enabled,
							fullName: user.fullname,
							phone: user.phonenumber,
							email: user.email,
							language: user?.preferences?.language,
							roles: user.roles,
							tags: user.tags,
							ip_address: '{{auto}}',
						});
						authEffectRef$.destroy();
					});
				}
			}
		});
	}

	/* ----------------------------------------------- LIFECYCLE HOOKS ----------------------------------------------- */
	ngOnInit() {
		// fire the 'angular-ready' window	event
		// window.dispatchEvent(new Event('angular-ready'));
		const urlCheckResults: boolean = this.authService.checkActiveURL();
		console.debug('URL Check Results:', urlCheckResults);
	}

	ngAfterViewInit(): void {
		this.messageService.add({key: 'APP_TOAST', life: 0, styleClass: 'invisible'});
	}
}
